import React from "react";
import { Link } from "gatsby";
import DesktopLayout from "./desktopLayout";
import {
  dskPage, dskPageWrapper, dskPageTitle,
} from "../../style/layout/_page.module.scss";
import {
  dskAboutWrapper, tagline, dskAboutContent,
  imageOne, dskDesignProcess, dskAboutText,
  sectionTitle, section, dskProcessCardWrapper, 
  dskContactContent, dskContentDescription, dskAddress
} from "../../style/layout/_about.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import ProcessDesktopCard from "../cards/processDesktopCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import Copyright from "../copyright";

const DesktopAboutLayout = ({...data}) => {
  console.log(data);

  return (
    <DesktopLayout>
      <div className={dskPage}>
        <div className={dskPageWrapper}>
          <div className={dskPageTitle}>ABOUT</div>
          <div className={dskAboutWrapper}>
            <div className={dskAboutContent} id="about-us">
              <div className={imageOne}>
              <StaticImage
                src="../../images/projects/kemvill01.jpg"
                alt="kemangvillageapartement"
              />
            </div>
              <div className={dskAboutText}>
                <div className={tagline}>
                  <span>BRINGS DELIGHT</span>
                  <span>TO YOUR ROOM</span>
                </div>
                <div>
                  <p>
                    We believe that a space with the right design can be enjoyed
                    as you enjoy a warm tea in a cold dark night, or a scoop of
                    ice cream in a hot sunny day. We design room interior and
                    decoration so that you can enjoy your room at its best. Our
                    scope of works includes designs, fabrications, and
                    installation. Browse our <Link to="/projects">past projects</Link> and learn more about how our{" "}
                    <a href="#design-process">design process</a> works
                  </p>
                </div>
              </div>
            </div>
          <div className={`${dskDesignProcess} ${section}`} id="design-process">
            <div className={sectionTitle}>DESIGN PROCESS</div>
            <div className={dskProcessCardWrapper}>
              {data.allProcessJson.nodes.map((step, index) => {
                return (<ProcessDesktopCard {...step} key={index}/>)
              })}
            </div>
          </div>
          <div className={`${section}`} id="contact-us">
          <div className={sectionTitle}>CONTACT US</div>
          <div className={dskContactContent}>
              <div className={dskContentDescription}>
              <h3>Inquiry</h3>
              <p>
                We love to hear from you. If you have inquiry about anything,
                the quickest way to reach us is by sending us email at{" "}
                <a href="mailto:hello@tekomerah.com">
                  hello@tekomerah.com
                </a>
              </p>
              <h3>Work with us</h3>
              <p>
                We are always looking for talented creative people to join us in
                our journey. If you have background in <em>architecture</em>,
                <em>interior</em> or <em>product design</em>, please send us
                your cover letter and portfolio. Even though, currently we do
                not have any open position, but we will get in touch as soon as
                we can fit you in!
              </p>
              </div>
                <div className={dskAddress}>
                <h3>TEKO MERAH</h3>
                  <address>
                    <div>
                      <FontAwesomeIcon icon={faMapPin} />
                    </div>
                    <div>
                      <span>
                        Jl. Jatiluhur Raya Blok DB5
                        <br />
                        Bekasi 17145
                      </span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <div>
                      <a href="mailto:hello@tekomerah.com">
                        <span>hello@tekomerah.com</span>
                      </a>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div>
                      <a href="tel:+622189457883">
                        <span>+62 21 89457883</span>
                      </a>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faInstagram} />
                    </div>
                    <div>
                      <a href="https://instagram.com/tekomerah">
                        <span>@tekomerah</span>
                      </a>
                    </div>
                  </address>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
      <Copyright/>
    </DesktopLayout>
  );
}

export default DesktopAboutLayout;
