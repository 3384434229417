import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import { pageTitle } from "../../style/layout/_page.module.scss";
import {
  aboutContent,
  textContent,
  firstBox,
  secondBox,
  aboutImageOne,
  aboutImageTwo,
  buttonWrapper,
} from "../../style/layout/_about.module.scss";
import Page from "../../components/page";
import { isMobile } from "react-device-detect";
import DesktopAboutLayout from "../../components/desktop/desktopAboutLayout";

const About = ({data}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Teko Merah</title>
        <meta name="author" content="readmerisen" />
        <meta
          name="description"
          content="Teko Merah is a growing interior design studio.
          We design room interior and decoration so that you can enjoy your room at its best."
        />
        <meta name="twitter:title" content="About Teko Merah Design Studio"></meta>
      </Helmet>
      {isMobile ? 
      <Page>
          <div className="about-page page-content">
            <div className={pageTitle}>ABOUT US</div>
            <div className={aboutContent}>
              <div className={textContent}>
                <h1>
                  Bring delight
                  <br />
                  <span>to your room</span>
                </h1>
                <div className={firstBox}>
                  <p>
                    We believe that a space with the right design can be enjoyed
                    as you enjoy a warm tea in a cold dark night, or a scoop of
                    ice cream in a hot sunny day. We design room interior and
                    decoration so that you can enjoy your room at its best.
                  </p>
                </div>
              </div>
              <div className={textContent}>
                <div className={secondBox}>
                  <p>
                    Our scope of works includes designs, fabrications, and
                    installation. Feel free to learn more about how our design
                    process works:
                  </p>
                  <div className={buttonWrapper}>
                    <Link to="/about/process">
                      <button>DESIGN PROCESS</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={aboutImageOne}>
                <StaticImage
                  src="../../images/projects/kemvill01.jpg"
                  alt="Kemvill Interior"
                />
              </div>
              <div className={aboutImageTwo}>
                <StaticImage
                  src="../../images/projects/kemvill02.jpg"
                  alt="Kemvill Interior"
                />
              </div>
            </div>
          </div>
        </Page>
        : 
        <DesktopAboutLayout {...data}/>
      }
    </>
  );
};

export const query = graphql`
  query{
    allProcessJson {
      nodes {
        id
        title
        content
        icon {
          src {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
          alt
        }
      }
    }
  }`;

export default About;
