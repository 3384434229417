import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { dskProcessCard, dskIcon, dskProcessInfo,
   dskArrow, dskIconWrapper, dskProcessContent}
  from '../../style/components/_processcard.module.scss';

const ProcessDesktopCard = ({...props}) => {
  const { title, content } = props;
  const thisIcon = getImage(props.icon.src.childImageSharp.gatsbyImageData);
  const iconAlt = props.icon.alt;
  return ( 
    <div className={dskProcessCard}>
      <div className={dskIconWrapper}>
        <div className={dskIcon}>
          <GatsbyImage
          image={thisIcon}
          alt={iconAlt}/>
        </div>
      </div>
      <div className={dskProcessInfo}>
        <h3 className="process-card-title">{title}</h3>
      </div>
      <div className={dskProcessContent}>
        <p>{content}</p>
      </div>
      {props.title !== "Fabrication & Installation" ? <div className={dskArrow}>
        <FontAwesomeIcon icon={faArrowRight}/>
      </div> :
      <div></div>}
    </div>
   );
}
 
export default ProcessDesktopCard;