// extracted by mini-css-extract-plugin
export var aboutPage = "_about-module--about-page--1rB5H";
export var aboutContent = "_about-module--about-content--1V2f2";
export var textContent = "_about-module--text-content--1BlVz";
export var firstBox = "_about-module--first-box--1PFWj";
export var secondBox = "_about-module--second-box--3QKIc";
export var buttonWrapper = "_about-module--button-wrapper--3ZQ3O";
export var aboutImageOne = "_about-module--about-image-one--1UcEc";
export var aboutImageTwo = "_about-module--about-image-two--1Wf6U";
export var dskAboutWrapper = "_about-module--dsk-about-wrapper--2wmuU";
export var dskAboutContent = "_about-module--dsk-about-content--3Ifu7";
export var dskAboutText = "_about-module--dsk-about-text--18NF9";
export var tagline = "_about-module--tagline--Y18yX";
export var imageOne = "_about-module--image-one--tdMPj";
export var section = "_about-module--section--26aUO";
export var sectionTitle = "_about-module--section-title--3mGMe";
export var dskDesignProcess = "_about-module--dsk-design-process--1BN8z";
export var dskProcessCardWrapper = "_about-module--dsk-process-card-wrapper--QMail";
export var dskContactContent = "_about-module--dsk-contact-content--3idvc";
export var dskContentDescription = "_about-module--dsk-content-description--3vLRK";
export var dskAddress = "_about-module--dsk-address--1K4M8";